import React from 'react'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import BrandDetailStories from 'components/Brands/BrandDetailStories'
import { BrandsStore } from 'stores/brands'
import { StoriesStore } from 'stores/stories'
import { useDatePagination, useStore, useFeedPageView } from 'hooks'
import { getStaticWrapper } from 'utils'
import { formatBrandStructuredDataMarkup } from 'lib/seo'

const BrandDetailPage = ({ brand, stories, featuredStories, queryOptions }) => {
  useStore(BrandsStore, { current: brand })
  const storiesStore = useStore(StoriesStore, { items: stories, featuredItems: featuredStories })
  const pagination = useDatePagination(
    storiesStore.items,
    (query) => storiesStore.findNext({ ...query, ...queryOptions }),
    {},
    [brand.id]
  )
  useFeedPageView(pagination, { pageType: 'Shows' }, [brand.id])

  return (
    <SiteLayout>
      <Head
        title={`${brand.name} | Blogs, Podcasts and Videos | Barstool Sports`}
        description={`Get all of the latest ${brand.name} blogs, videos and podcasts.`}
        ogImage={`${brand.cover_photo}?crop=1200:630,smart`}
        canonical={`${process.env.SITE_URL}/shows/${brand.id}/${brand.slug}`}
      />
      <>{formatBrandStructuredDataMarkup(brand)}</>
      <BrandDetailStories {...pagination} featured={featuredStories} title='Activity' brand={brand} />
    </SiteLayout>
  )
}

export const getStaticProps = getStaticWrapper(async ({ params }) => {
  const { id } = params

  const brand = await BrandsStore().readWithVideo(id)

  const queryOptions = {
    brand: brand.story_category ? null : brand.id,
    category: brand.story_category || null
  }

  const featuredQueryOptions = {
    ...queryOptions,
    featured_section: true,
    limit: 3
  }

  const [stories, featuredStories] = await Promise.all([
    StoriesStore().find(queryOptions),
    StoriesStore().find(featuredQueryOptions)
  ])

  return {
    brand,
    stories,
    featuredStories,
    queryOptions
  }
})

export async function getStaticPaths() {
  const brands = await BrandsStore().find()
  return {
    paths: brands.map((doc) => ({
      params: {
        id: String(doc.id),
        slug: doc.slug
      }
    })),
    fallback: 'blocking'
  }
}

export default BrandDetailPage
